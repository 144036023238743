<template>
  <div class="Contactor" v-loading="loading">
    <div class="ContactorTitle">{{ $t("lang.SText55") }}</div>
    <div class="ContactorMain">
      <div class="list">
        <div v-for="(item, index) in listData" :key="index">
          <div
            class="itemData"
            @mouseover="signOutHover(index)"
            @mouseleave="signOutHover1(index)"
            :class="{ hover: item.hover == true }"
          >
            <div class="status" v-if="item.type == '10'">
              {{ $t("lang.SText56") }}
            </div>
            <div class="status" v-if="item.type == '20'">
              {{ $t("lang.SText57") }}
            </div>
            <div class="status" v-if="item.type == '30'">
              {{ $t("lang.SText58") }}
            </div>
            <div class="status" v-if="item.type == '40'">
              {{ $t("lang.SText59") }}
            </div>
            <div class="userInfo">
              <div class="userHead" v-if="true">
                <svg-icon icon-class="userHead"></svg-icon>
              </div>
              <div class="userHead" v-if="false">
                <el-image class="image" src=""></el-image>
              </div>
              <div class="info">
                <div class="userName">
                  {{ item.firstName }}
                  {{ item.lastName }}
                </div>
                <div class="userPhone">
                  {{ item.mobile }}
                  {{ item.phone.split(" ")[1] ? item.phone : "" }}
                </div>
                <div class="email">{{ item.email }}</div>
                <div class="location" v-if="item.countryOrRegion">
                  <svg-icon icon-class="location"></svg-icon>
                  <div class="country">{{ item.countryOrRegionShow }}</div>
                </div>
              </div>
            </div>
            <div class="edit">
              <div
                class="icon"
                v-show="item.hover && item.type != 10"
                @click="edit(item)"
              >
                <svg-icon icon-class="edit"></svg-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="editFlag"
      width="70%"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="dialog-title1">
        <div class="left">
          <svg-icon icon-class="userHead" class="svg-icon"></svg-icon>
          {{ $t("lang.SText60") }}
        </div>
        <div class="close" @click="closeDialog()">
          <svg-icon class="close-icon" icon-class="close"></svg-icon>
        </div>
      </div>
      <div class="editInfo" v-loading="loadings">
        <div class="left">
          <label for="Staff">{{ $t("lang.SText61") }}</label>
          <el-select
            class="date-card-select"
            v-model="staffChoose"
            :placeholder="$t('lang.BText80')"
            filterable
            @change="staffChange"
          >
            <el-option
              v-for="item in staffData"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <div class="center">{{ $t("lang.SText62") }}</div>
        <div class="right">
          <el-form :model="form" :rules="rules" ref="addressForm">
            <div class="dw-card2">
              <div class="main" style="padding: 0">
                <el-form-item
                  class="form-item"
                  prop="firstName"
                  :label="$t('lang.SText63')"
                  style="
                    width: 190px;
                    display: inline-block;
                    margin-right: 20px;
                  "
                >
                  <el-input v-model="form.firstName"></el-input>
                </el-form-item>
                <el-form-item
                  class="form-item"
                  prop="lastName"
                  :label="$t('lang.SText64')"
                  style="width: 190px; display: inline-block"
                >
                  <el-input v-model="form.lastName"></el-input>
                </el-form-item>
                <el-form-item
                  class="form-item"
                  prop="email"
                  :label="$t('lang.SText65')"
                >
                  <el-input v-model="form.email"></el-input>
                </el-form-item>
                <el-form-item
                  class="form-item"
                  prop="countryOrRegion"
                  :label="$t('lang.SText66')"
                >
                  <el-select
                    style="width: 100%"
                    class="date-card-select"
                    v-model="form.countryOrRegion"
                    :placeholder="$t('lang.BText80')"
                    filterable
                    @change="clickCountry"
                    remote
                    :remote-method="findcountryOrRegionList"
                    :loading="selectLoading"
                  >
                    <el-option
                      v-for="(item, index) in countryList"
                      :key="index"
                      :label="item.name"
                      :value="item.code"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  class="form-item"
                  prop="mobile"
                  :label="$t('lang.SText67')"
                >
                  <el-select
                    class="step1-select"
                    v-model="form.dialCode1"
                    placeholder=""
                    filterable
                    remote
                    :remote-method="findPhoneList"
                    :loading="selectLoading"
                  >
                    <el-option
                      v-for="(item, index) in dialCodeList"
                      :key="index"
                      :label="item.iso2"
                      :value="item.iso2"
                    >
                      <div class="phoneOption">
                        {{ item.iso2 + " | " + item.cname }}
                      </div>
                    </el-option>
                  </el-select>
                  <el-input
                    class="unspin"
                    v-model="form.mobile"
                    style="width: 75%"
                    type="number"
                    @keydown.native="limite"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item
                  class="form-item"
                  prop="phone"
                  :label="$t('lang.SText53')"
                >
                  <el-select
                    class="step1-select"
                    v-model="form.dialCode2"
                    placeholder=""
                    filterable
                    remote
                    :remote-method="findPhoneList"
                    :loading="selectLoading"
                  >
                    <el-option
                      v-for="(item, index) in dialCodeList"
                      :key="index"
                      :label="item.iso2"
                      :value="item.iso2"
                    >
                      <div class="phoneOption">
                        {{ item.iso2 + " | " + item.cname }}
                      </div>
                    </el-option>
                  </el-select>
                  <el-input
                    v-model="form.phone"
                    style="width: 75%"
                    class="phoneInput unspin"
                    type="number"
                    @keydown.native="limite"
                  >
                  </el-input>
                </el-form-item>
              </div>
            </div>
          </el-form>
        </div>
        <div class="bottom-button" style="width: 100%">
          <el-button
            type="primary"
            :loading="loadSave"
            @click="Save('addressForm')"
            >{{ $t("lang.SText13") }}</el-button
          >
          <el-button type="info" @click="Cancel">{{
            $t("lang.QText79")
          }}</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import dialCode from "../../../utils/phone/index";
import dwlist from "../../../components/dwList/dwlist.vue";
export default {
  components: {
    dwlist,
  },
  data() {
    let checkMobile = (rule, value, callback) => {
      if (this.form.dialCode1 == "86") {
        // 验证手机号的正则表达式
        const regMobile = /^1(3|4|5|6|7|8|9)\d{9}$/;
        if (regMobile.test(value)) {
          // 合法的手机号
          return callback();
        }
        // 不合法
        callback(new Error(this.$t("lang.SText11")));
      } else if (this.form.dialCode1 == "1") {
        const regMobile = /^[2-9]\d{2}[2-9](?!11)\d{6}$/;
        if (regMobile.test(value)) {
          // 合法的手机号
          return callback();
        }
        // 不合法
        callback(new Error(this.$t("lang.SText11")));
      } else {
        const regMobile = /^[1-9]\d*$/;
        if (regMobile.test(value)) {
          // 合法的手机号
          return callback();
        }
        // 不合法
        callback(new Error(this.$t("lang.SText11")));
      }
    };
    return {
      staffChoose: "",
      staffData: [],
      countryList: [],
      editFlag: false,
      loading: false,
      loadings: false,
      listData: [],
      newArry: [],
      hover: false,
      loadSave: false,
      pageSize: 3,
      pageNo: 1,
      total: 0,
      currentPage: 1,
      form: {
        dialCode2: "",
        dialCode1: "",
      },
      userinfo: {},
      selectLoading: false,
      token: "",
      rules: {
        firstName: [
          { required: true, message: this.$t("lang.SText165") },
          { min: 1, max: 20, message: this.$t("lang.SText164") },
        ],
        lastName: [
          { required: true, message: this.$t("lang.SText166") },
          { min: 1, max: 20, message: this.$t("lang.SText164") },
        ],
        email: [
          { required: true, message: this.$t("lang.SText167") },
          { type: "email", message: this.$t("lang.SText168") },
          { min: 1, max: 50, message: this.$t("lang.SText169") },
        ],
        countryOrRegion: [
          {
            required: true,
            message: this.$t("lang.SText161"),
            trigger: "change",
          },
        ],
        mobile: [
          { validator: checkMobile },
          { required: true, message: this.$t("lang.NText120") },
          { min: 1, max: 20, message: this.$t("lang.SText164") },
        ],
        // city: [
        //   { required: true, message: 'please select city', trigger: 'change' }
        // ],
        phone: [
          { required: false, message: this.$t("lang.SText155") },
          { min: 1, max: 20, message: this.$t("lang.SText164") },
        ],
      },
      dialCodeList: [],
    };
  },
  computed: {
    // dialCodeList: function () {
    //   return dialCode;
    // },
  },
  created() {
    this.$session("countryList").get((value) => {
      this.countryList = value;
    });
    this.$session("userInfo").get((value) => {
      this.userinfo = value;
    });
    this.$session("token").get((value) => {
      this.token = value;
    });
    this.initData();
    this.staffList();
  },
  methods: {
    limite(e) {
      let key = e.key;
      if (key === "e" || key === ".") {
        e.returnValue = false;
        return false;
      }
      return true;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.initData();
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.initData();
    },
    signOutHover(index) {
      this.listData[index].hover = true;
    },
    signOutHover1(index) {
      this.listData[index].hover = false;
    },
    initData() {
      let that = this;
      that.loading = true;
      let data = {
        companyId: that.userinfo.companyid,
        // companyId:"1481550332951117824",
        pageSize: that.pageSize,
        pageNo: that.pageNo,
      };
      that.$ajax
        .cPost(that.source.basCompany_api.contactlist, data)
        .then((res) => {
          if (res.data.result == "true") {
            that.loading = false;
            res.data.list.forEach((element) => {
              element.hover = false;
            });
            that.listData = res.data.list;
            that.total = res.data.allCount;
          } else {
            that.loading = true;
            that.listData = [];
            that.$notify.error({
              title: res.data.message,
              offset: 100,
              duration: 3000,
            });
          }
        });
    },
    staffList() {
      let that = this;
      let data = {
        companyId: that.userinfo.companyid,
        // companyId:"1481550332951117824"
      };
      that.$ajax
        .cPost(that.source.basCompany_api.staffData, data)
        .then((res) => {
          if (res.data.result == "true") {
            let newData = [];
            let resultData = res.data.data ? JSON.parse(res.data.data) : "";
            that.newArry = resultData;
            for (let key in resultData) {
              newData.push(key);
            }
            that.staffData = newData;
          } else {
            that.staffData = [];
          }
        });
    },
    edit(item) {
      console.log(item.phone);
      this.$session("countryList").get((value) => {
        this.countryList = value;
      });
      this.getPhoneList({ country: "" });
      this.editFlag = true;
      this.form.id = item.id;
      this.staffChoose = "";
      this.$set(this.form, "firstName", item.firstName);
      this.$set(this.form, "lastName", item.lastName);
      this.form.role = item.role;
      this.$set(
        this.form,
        "phone",
        item.phone.split(" ")[1] ? item.phone.split(" ")[1] : ""
      );
      this.$set(this.form, "mobile", item.mobile.split(" ")[1]);
      this.$set(this.form, "email", item.email);
      this.$set(this.form, "dialCode1", item.mobile.split(" ")[0]);
      this.$set(this.form, "dialCode2", item.phone.split(" ")[0]);
      this.$set(
        this.form,
        "countryOrRegion",
        item.countryOrRegion.split(" ")[0]
      );
      this.form.type = item.type;
      // 清除表单校验的提示
      if (this.$refs["addressForm"]) {
        // 延时执行
        this.$nextTick(function () {
          this.$refs["addressForm"].clearValidate();
        });
      }
    },
    staffChange(e) {
      this.form.phone = "";
      this.form.mobile = "";
      this.form.firstName = "";
      this.form.lastName = "";
      this.form.email = "";
      this.form.countryOrRegion = "";
      for (let key in this.newArry) {
        if (e == key) {
          if (this.newArry[e].mobile) {
            let arr1 = this.newArry[e].mobile.split(" ");
            if (arr1[1]) {
              this.form.dialCode1 = arr1[0];
            }
          }
          if (this.newArry[e].phone) {
            let arr1 = this.newArry[e].phone.split(" ");
            if (arr1[1]) {
              this.form.dialCode2 = arr1[0];
            }
          }
          this.form.phone = this.newArry[e].phone
            ? this.newArry[e].phone.split(" ")[1]
            : "";
          this.form.mobile = this.newArry[e].mobile
            ? this.newArry[e].mobile.split(" ")[1]
            : "";
          this.form.firstName = this.newArry[e].firstName
            ? this.newArry[e].firstName
            : "";
          this.form.lastName = this.newArry[e].lastName
            ? this.newArry[e].lastName
            : "";
          this.form.email = this.newArry[e].email ? this.newArry[e].email : "";
          this.form.countryOrRegion = this.newArry[e].countryOrRegion
            ? this.newArry[e].countryOrRegion
            : "";
        }
      }
    },
    findcountryOrRegionList(e) {
      this.selectLoading = true;
      this.$ajax
        .cPost(this.source.address_api.getCountryList, { name: e })
        .then((res) => {
          this.selectLoading = false;
          if ((res.data.result = "true")) {
            this.countryList = res.data.data;
          } else {
            this.$notify.error({
              title: res.data.message,
              offset: 100,
              duration: 3000,
            });
          }
        });
    },
    clickCountry(e) {
      console.log(e);
      this.$set(this.form, "dialCode1", "");
      this.$set(this.form, "dialCode2", "");
      if (e) {
        this.getPhoneList({ country: e }, "1");
      }
    },
    getPhoneList(data, val) {
      let that = this;
      that.$ajax.cPost(that.source.address_api.phone, data).then((res) => {
        if (res.data.result) {
          if (val == "1") {
            that.$set(that.form, "dialCode1", res.data.data[0].iso2);
            that.$set(that.form, "dialCode2", res.data.data[0].iso2);
          } else {
            that.dialCodeList = res.data.data;
          }
        } else {
          that.$notify.error({
            title: res.message,
            offset: 100,
            duration: 3000,
          });
        }
      });
    },
    findPhoneList(e) {
      this.selectLoading = true;
      this.$ajax
        .cPost(this.source.address_api.phone, {
          dialCode: e,
          // country:this.form.countryOrRegion
        })
        .then((res) => {
          console.log(res);
          this.selectLoading = false;
          if ((res.data.result = "true")) {
            this.dialCodeList = res.data.data;
          } else {
            this.$notify.error({
              title: res.data.message,
              offset: 100,
              duration: 3000,
            });
          }
        });
    },
    Save(formName) {
      var that = this;
      console.log(that.form.phone);
      that.$refs[formName].validate((valid) => {
        if (valid) {
          that.loadSave = true;
          let data = {
            id: that.form.id,
            firstName: that.form.firstName,
            lastName: that.form.lastName,
            role: that.form.role,
            phone: that.form.dialCode2 + " " + that.form.phone,
            mobile: that.form.dialCode1 + " " + that.form.mobile,
            email: that.form.email,
            countryOrRegion: that.form.countryOrRegion,
            type: that.form.type,
            // companyId:"1481550332951117824",
            companyId: that.userinfo.companyid,
          };
          console.log(data);
          that.$ajax
            .cPost(that.source.basCompany_api.staffDataedit, data)
            .then((res) => {
              if (res.data.result) {
                that.editFlag = false;
                that.loadSave = false;
                that.initData();
                that.$notify({
                  title: res.data.message,
                  type: "success",
                  offset: 100,
                  duration: 3000,
                });
              } else {
                that.editFlag = true;
                that.loadSave = false;
                that.$notify.error({
                  title: res.data.message,
                  offset: 100,
                  duration: 3000,
                });
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    closeDialog() {
      this.editFlag = false;
    },
    Cancel() {
      this.editFlag = false;
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/base.scss";
.Contactor {
  width: 900px;
  text-align: left;
  margin-left: 150px;

  .el-dialog {
    margin-top: 0 !important;
    min-height: 100%;
    margin: 0 auto;
    .el-dialog__header {
      padding: 0;
      display: none;
    }

    .el-dialog__body {
      padding: 0;
      min-height: 100%;
      // overflow: auto;
      .editInfo {
        width: 770px;
        margin: auto;
        padding-bottom: 40px;
        .el-input__inner {
          height: 48px;
        }
        .form-line {
          display: flex;
          justify-content: space-between;
          .form-item {
            width: 48%;
            position: relative;
          }
        }
        .el-form-item {
          display: flex;
          flex-direction: column;
        }
        .bottom-button {
          margin: auto;
          width: 400px;
          .el-button {
            width: 180px;
            height: 64px;
          }
        }
        .step1-select {
          width: 100px;
        }
        .phoneInput {
          width: 240px;
        }
        .tagList {
          width: 500px;
        }
      }
      .editInfo {
        margin-top: 30px;
        padding: 0 255px;
        float: left;
        text-align: center;
        .left {
          width: 250px;
          float: left;
          label {
            width: 100%;
            font-size: 20px;
            color: #808080;
            text-align: left;
            display: flex;
            flex-direction: row-reverse;
            justify-content: left;
            line-height: 40px;
          }
        }
        .right {
          float: left;
          width: 400px;
          .el-form-item__label {
            font-size: 20px;
            padding-left: 15px;
            color: grey;
            text-align: left;
            display: flex;
            flex-direction: row;
            justify-content: left;
          }
          .is-required .el-form-item__label {
            color: #132569;
          }
        }
        .center {
          width: 120px;
          float: left;
          text-align: center;
          font-family: "montserratregular";
          font-size: 20px;
          color: #132569;
          line-height: 40px;
          margin-top: 40px;
        }
      }
    }
  }
  .ContactorTitle {
    font-size: 36px;
    color: $mainColor;
    margin-bottom: 40px;
  }
  .ContactorMain {
    .list {
      border-top: 1px solid $borderColor1;
      .hover {
        background-color: #f9f9f9;
      }
      .itemData {
        display: flex;
        padding: 40px 20px;
        justify-content: space-between;
        border-bottom: 1px solid $borderColor1;
        .status {
          flex: 5;
          color: $fontColor3;
        }
        .userInfo {
          display: flex;
          flex: 10;
          .userHead {
            width: 48px;
            height: 48px;
            border-radius: 24px;
            border: 1px solid $borderColor1;
            overflow: hidden;
            margin-right: 20px;
            .svg-icon {
              color: $fontColor3;
              width: 28px;
              height: 28px;
              margin: 8px 10px 10px 10px;
              fill: $fontColor3;
              stroke: $fontColor3;
            }
            .image {
              width: 48px;
              height: 48px;
            }
          }
          .info {
            .userName {
              color: $mainColor;
              font-size: 30px;
              margin-bottom: 15px;
              width: 400px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              -o-text-overflow: ellipsis;
            }
            .userPhone {
              font-size: 20px;
              color: $fontColor3;
              margin-bottom: 15px;
            }

            .email {
              font-size: 20px;
              color: $mainColor;
              margin-bottom: 15px;
            }

            .location {
              display: flex;
              font-size: 20px;
              color: $background;
              .svg-icon {
                margin-right: 5px;
                fill: $background;
                stroke: $background;
              }
            }
          }
        }
        .edit {
          flex: 1;
          text-align: center;
          .icon {
            height: 100%;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>